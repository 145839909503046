import React, { useState } from 'react';
import { get, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  formatPoliciesToSelector,
  formatEmailVariablesToSelector,
  sendErrorReport,
  checkUserPermission,
} from 'shared/helpers';
import {
  validateEmail,
  debouncedValidateEmail,
} from 'shared/validation';
import { userPermissions } from 'shared/constants';
import {
  Button,
  CheckboxSelector,
  ColorPicker,
  Label,
  Notice,
  Selector,
  TextInput,
  InputErrorMessage,
  Notification,
  PermissionMissingNotificationTitle,
  RichTextEditor,
  Subtitle,
} from 'shared/components';
import {
  updateNotificationPolicy,
  getNotificationPolicies,
  resetNotificationPolicy,
  testSendNotificationPolicy,
} from 'src/notifications/actions';
import EmailPreview from '../EmailPreview';
import './styles.scss';

const getActiveVariables = vars => vars.filter(v => get(v, 'data.show'));

const mapVariables = (list, selected) => {
  const result = list.map((i) => {
    const isSelected = selected.find(s => get(s, 'data.code') === get(i, 'data.code'));
    if (isSelected) {
      return ({
        ...i.data,
        show: true,
      });
    }

    return ({
      ...i.data,
      show: false,
    });
  });

  return result;
};

const EmailConfiguration = ({ setDisableTabsChange }) => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write);
  const dispatch = useDispatch();
  const products = useSelector(state => get(state, 'products.list'));
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const emailSender = get(companyDetails, 'email_sender');
  const companyID = get(companyDetails, 'id');
  const planType = get(companyDetails, 'plan_type');
  const isEnterprise = planType === 'enterprise';
  const notificationPolicies = useSelector(state => get(state, 'notifications.notification_policies') || []);
  const policiesOptions = formatPoliciesToSelector(notificationPolicies, products, __('All products'));
  const initialPolicy = get(policiesOptions, '[0]');
  const initialEmailOptions = get(policiesOptions, '[0].email_options');

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(initialPolicy);
  const [subject, setSubject] = useState(get(initialEmailOptions, 'subject'));
  const [title, setTitle] = useState(get(initialEmailOptions, 'title'));
  const [text, setText] = useState(get(initialEmailOptions, 'text'));
  const [accentColor, setAccentColor] = useState(get(initialEmailOptions, 'color') || '');
  const [variables, setVariables] = useState(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.variables')));
  const [selectedVariables, setSelectedVariables] = useState(getActiveVariables(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.variables'))));
  const [userPortalVariables, setUserPortalVariables] = useState(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.user_portal_variables')));
  const [selectedUserPortalVariables, setSelectedUserPortalVariables] = useState(getActiveVariables(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.user_portal_variables'))));
  const [licensesVariables, setLicensesVariables] = useState(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.license_list_variables')));
  const [selectedLicensesVariables, setSelectedLicensesVariables] = useState(getActiveVariables(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.license_list_variables'))));
  const [testEmailRecipient, setTestEmailRecipient] = useState('');
  const [recipientEmailError, setRecipientEmailError] = useState('');
  const [sendingMail, setSendingMail] = useState(false);

  setDisableTabsChange(isDirty);

  const validateRecipientEmail = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateEmail(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate recipient email', { value: val });
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const handleTestEmailSend = async () => {
    setSendingMail(true);
    setLoading(true);
    try {
      await validateRecipientEmail(testEmailRecipient);
      setLoading(false);
      setSendingMail(false);
      await testSendNotificationPolicy(selectedPolicy.id, companyID, { to_email: testEmailRecipient });
      Notification('success', __('Test email sent successfully'));
    } catch (err) {
      sendErrorReport(err, 'Cannot send test email');
      setLoading(false);
      setSendingMail(false);
      Notification('error', __('Error occured'));
    }
  };

  const handlePolicyChange = (policyID) => {
    const policy = policiesOptions.find(np => np.id === policyID);
    setSelectedPolicy(policy);
    setSubject(get(policy, 'email_options.subject'));
    setTitle(get(policy, 'email_options.title'));
    setText(get(policy, 'email_options.text'));
    setAccentColor(get(policy, 'email_options.color'));
    setVariables(formatEmailVariablesToSelector(get(policy, 'email_options.variables')));
    setSelectedVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.variables'))));
    setUserPortalVariables(formatEmailVariablesToSelector(get(policy, 'email_options.user_portal_variables')));
    setSelectedUserPortalVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.user_portal_variables'))));
    setLicensesVariables(formatEmailVariablesToSelector(get(policy, 'email_options.license_list_variables')));
    setSelectedLicensesVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.license_list_variables'))));
  };

  const resetValues = (policy) => {
    setSubject(get(policy, 'email_options.subject'));
    setTitle(get(policy, 'email_options.title'));
    setText(get(policy, 'email_options.text')); // fix this
    setAccentColor(get(policy, 'email_options.color'));
    setVariables(formatEmailVariablesToSelector(get(policy, 'email_options.variables')));
    setSelectedVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.variables'))));
    setUserPortalVariables(formatEmailVariablesToSelector(get(policy, 'email_options.user_portal_variables')));
    setSelectedUserPortalVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.user_portal_variables'))));
    setLicensesVariables(formatEmailVariablesToSelector(get(policy, 'email_options.license_list_variables')));
    setSelectedLicensesVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.license_list_variables'))));
  };

  const handleSubmit = () => {
    if (isLoading || !isDirty) {
      return false;
    }
    const policyID = get(selectedPolicy, 'id');
    const data = {
      email_options: {
        subject,
        title,
        text,
        color: accentColor,
        variables: mapVariables(variables, selectedVariables),
        user_portal_variables: mapVariables(userPortalVariables, selectedUserPortalVariables),
        license_list_variables: mapVariables(licensesVariables, selectedLicensesVariables),
      },
    };
    setLoading(true);

    updateNotificationPolicy(policyID, companyID, data)
      .then(() => {
        setLoading(false);
        setDirty(false);
        dispatch(getNotificationPolicies(companyID));
        Notification('success', __('Changes saved successfully'));
      })
      .catch((err) => {
        setLoading(false);
        setDirty(false);
        sendErrorReport(err, 'Cannot update notification policy email options', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const handlePolicyReset = () => {
    if (isLoading) {
      return false;
    }
    const policyID = get(selectedPolicy, 'id');
    setLoading(true);
    resetNotificationPolicy(policyID, companyID)
      .then((res) => {
        resetValues(get(res, 'data'));
        setLoading(false);
        setDirty(false);
        dispatch(getNotificationPolicies(companyID));
        Notification('success', __('Changes saved successfully'));
      })
      .catch((err) => {
        setLoading(false);
        setDirty(false);
        sendErrorReport(err, 'Cannot reset notification policy email options');
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const handleManageNotificationsClick = (cb) => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  const toggleQRCode = () => {
    const existingQRCode = selectedVariables.find(item => item.value === 'qr_code');
    const qrCodeInVariables = variables.find(item => item.data.code === 'qr_code');
    const isQRshowing = qrCodeInVariables.data.show;

    if (existingQRCode || isQRshowing) {
      // remove QR code from selected variables
      setSelectedVariables(selectedVariables.filter(item => item.value !== 'qr_code'));
    } else {
      // Add new QR code to selected variables
      setSelectedVariables([
        ...selectedVariables,
        {
          value: 'qr_code',
          label: 'QR code',
          data: {
            code: 'qr_code',
            label: 'QR code',
            show: true,
          },
        },
      ]);
    }
    setDirty(true);
  };

  const hasQrCode = variables.some(item => item.data.code === 'qr_code');

  return (
    <div className="EmailConfiguration">
      <Prompt
        when={isDirty}
        message={__('You have unsaved changes. Are you sure you want to leave?')}
      />
      {!isEnterprise && (
        <Notice size="sm" title={__('Customizing email templates is not available in your plan type.')} theme="warning">
          <div>{__('Upgrade your plan to use this feature.')}</div>
        </Notice>
      )}
      {emailSender ? (
        <div className="EmailConfiguration-sender">
          <Label text={__('Email address')} inputId="email-sender" />
          <div className="email">{emailSender}</div>
        </div>
      ) : (
        <Notice size="sm" theme="default">
          <div>{__('Contact sales if you want to enable custom email sender.')}</div>
        </Notice>
      )}
      <div className="EmailConfiguration-title">
        <Subtitle text={__('Configure email template')} />
      </div>
      <div className="Wrapper">
        <div className="Configurator">
          <div className="form-row event-selector">
            <Label inputId="events-selector" text={__('Notification Policy')} />
            <Selector
              options={policiesOptions}
              value={selectedPolicy.value}
              handleChange={handlePolicyChange}
              disabled={isLoading}
            />
          </div>
          <div className="form-row">
            <Label inputId="subject-input" text={__('Subject')} />
            <TextInput
              disabled={isLoading || !isEnterprise}
              id="subject-input"
              type="text"
              value={subject}
              handleChange={(val) => {
                setDirty(true);
                setSubject(val);
              }}
            />
          </div>
          <div className="form-row">
            <Label inputId="title-input" text={__('Title')} />
            <TextInput
              disabled={isLoading || !isEnterprise}
              id="title-input"
              type="text"
              value={title}
              handleChange={(val) => {
                setDirty(true);
                setTitle(val);
              }}
            />
          </div>
          <div className="form-row">
            <Label inputId="text-input" text={__('Text')} />
            <RichTextEditor
              id={get(selectedPolicy, 'id')}
              content={text}
              onChange={(val) => {
                setDirty(true);
                setText(val);
              }}
            />
          </div>
          {accentColor ? (
            <div className="form-row">
              <Label text={__('Accent color')} inputId="new-label-color" />
              <ColorPicker
                initialColor={accentColor}
                onColorSelect={(val) => {
                  setDirty(true);
                  setAccentColor(val);
                }}
                label={accentColor}
                disabled={isLoading || !isEnterprise}
              />
            </div>
          ) : null}
          {variables.length ? (
            <div className="form-row checkbox">
              <CheckboxSelector
                text={__('Select fields to show')}
                options={sortBy(
                  variables.filter(item => item.value !== 'qr_code'),
                  'label',
                )}
                disabled={isLoading || !isEnterprise}
                value={selectedVariables}
                onChangeCallback={(val) => {
                  setDirty(true);
                  setSelectedVariables(val);
                }}
                onMenuClose={() => { }}
              />
            </div>
          ) : null}
          {userPortalVariables.length ? (
            <div className="form-row checkbox">
              <CheckboxSelector
                text={__('Select user portal fields')}
                options={sortBy(userPortalVariables, 'label')}
                value={selectedUserPortalVariables}
                onChangeCallback={(val) => {
                  setDirty(true);
                  setSelectedUserPortalVariables(val);
                }}
                onMenuClose={() => { }}
                disabled={isLoading || !isEnterprise}
              />
            </div>
          ) : null}
          {licensesVariables.length ? (
            <div className="form-row checkbox">
              <CheckboxSelector
                text={__('Select licenses list fields')}
                options={sortBy(licensesVariables, 'label')}
                value={selectedLicensesVariables}
                onChangeCallback={(val) => {
                  setDirty(true);
                  setSelectedLicensesVariables(val);
                }}
                onMenuClose={() => { }}
                disabled={isLoading || !isEnterprise}
              />
            </div>
          ) : null}
          {hasQrCode && (
          <div className="form-row checkbox flex-row-toggle">
            <label className="switch" htmlFor="qr-code-checkbox">
              <input
                id="qr-code-checkbox"
                type="checkbox"
                checked={selectedVariables.some(item => item.data.code === 'qr_code') || variables.some(item => item.data.code === 'qr_code').show}
                onChange={e => toggleQRCode(!e.target.checked)}
              />
              <span className="slider round" />
            </label>
            <Label text={__('Attach QR code to email')} />
          </div>
          )}
          <div className="buttons-container">
            <Button
              theme="success"
              disabled={isLoading || !isEnterprise}
              onClick={() => handleManageNotificationsClick(handleSubmit)}
            >
              {__('Save')}
            </Button>
            <Button
              theme="info"
              disabled={isLoading || !isEnterprise}
              onClick={() => handleManageNotificationsClick(handlePolicyReset)}
            >
              {__('Reset to default')}
            </Button>
          </div>
        </div>
        <div>
          <div className="test-email">
            <Label inputId="subject-input" text={__('Send test email')} />
            <div className="test-email-input-wrapper">
              <TextInput
                disabled={isLoading || !isEnterprise}
                handleChange={(val) => {
                  setDirty(true);
                  setTestEmailRecipient(val);
                  debouncedValidateEmail(val).then((err) => {
                    if (!val) {
                      setRecipientEmailError('');
                    } else {
                      setRecipientEmailError(err);
                    }
                  });
                }}
                id="email-input"
                placeholder="test@example.com"
                type="email"
                value={testEmailRecipient}
                showErrorMsg={false}
              />
              <Button
                disabled={isLoading || !isEnterprise || !testEmailRecipient || recipientEmailError || sendingMail}
                loading={sendingMail}
                onClick={handleTestEmailSend}
              >
                {__('Send')}
              </Button>
            </div>
            <InputErrorMessage text={recipientEmailError} />
          </div>
          <EmailPreview
            policy={selectedPolicy}
            subject={subject}
            title={title}
            text={text}
            accentColor={accentColor}
            variables={selectedVariables.filter(item => item.value !== 'qr_code')}
            userPortalVariables={selectedUserPortalVariables}
            licensesVariables={selectedLicensesVariables}
          />
        </div>
      </div>
    </div>
  );
};

EmailConfiguration.propTypes = {
  setDisableTabsChange: PropTypes.func,
};

EmailConfiguration.defaultProps = {
  setDisableTabsChange: () => {},
};

export default EmailConfiguration;
